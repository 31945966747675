import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Update from "../components/update";
import PortableText from "../components/PortableText.js";
import { PageWrapperDiv } from "./../styledcomponents/layoutstyles";

export default function PageTemplate({ data, pageContext }) {
  // console.log(data, pageContext);
  const { sanityUpdate } = data;
  const { title, publishedAt, _rawBody } = sanityUpdate;
  const theDate = new Date(publishedAt);
  const month = theDate.toLocaleString("default", { month: "long" });
  return (
    <Layout title={`Ring On Deli: ${title}`}>
      <PageWrapperDiv>
        <Update
          html={null}
          title={title}
          date={`${month} ${theDate.getDate()}, ${theDate.getUTCFullYear()}`}
        >
          <PortableText blocks={_rawBody} />
        </Update>
      </PageWrapperDiv>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityUpdate(_id: { eq: $id }) {
      title
      publishedAt
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;
