import React from "react";
import styled from "styled-components";

const UpdateDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  margin: var(--outsidePadding) 0;
  font-family: var(--uiFont);
  & h1 {
    color: var(--red);
    margin: 0;
    margin-bottom: 28px;
    font-size: 32px;
    line-height: 36px;
  }
  & div {
    & p {
      margin: 0;
      font-size: 20px;
      line-height: 28px;

      & .sc {
        letter-spacing: 1px;
        text-transform: lowercase;
        -moz-font-feature-settings: "smcp";
        -webkit-font-feature-settings: "smcp";
        font-feature-settings: "smcp";
        letter-spacing: 2px;
      }
      & a {
        font-weight: bold;
      }
      & + p {
        text-indent: 28px;
      }
    }
    & hr {
      width: 50%;
      margin: 28px 25%;
    }
    & blockquote {
      border-left: 0;
      font-style: normal;
      margin: 28px;
      padding: 0;
      & p {
        margin: 0;
      }
      & + blockquote {
        margin-top: -28px;
      }
    }
  }
  & h2 {
    text-align: right;
    font-weight: normal;
    color: var(--blue);
    margin: 28px 0 0 0;
    font-size: 20px;
    line-height: 28px;
  }
`;

const Update = ({ html, date, title, children }) => {
  return (
    <UpdateDiv>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      {html ? (
        <div dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        <div>{children}</div>
      )}
      <h2 className="italic unbold">{date}</h2>
    </UpdateDiv>
  );
};

export default Update;
